#education-section {
	padding-top: 12rem;
	padding-bottom: 12rem;
}

#education-section .resume-header {
	text-align: center;
}
#education-section .resume-header h2 {
	color: #FF0077;
}

#education-section .resume-timeline {
	max-width: 980px;
}
#education-section .timeline-wrap {
	position: relative;
	margin-top: 1.5rem;
	margin-bottom: 6rem;
}
#education-section .timeline-wrap::before {
	content: "";
	display: block;
	width: 1px;
	height: 100%;
	background: rgb(255, 255, 255);
	position: absolute;
	left: 35%;
	top: 0;
}
#education-section .timeline-block {
	position: relative;
	padding-top: 1.5rem;
}
#education-section .timeline-ico {
	height: 3.8rem;
	width: 3.8rem;
	line-height: 3.8rem;
	background: #313131;
	border-radius: 50%;
	text-align: center;
	color: #FFFFFF;
	position: absolute;
	left: 35%;
	top: .9rem;
	margin-left: -1.9rem;
}
#education-section .timeline-ico i {
	position: relative;
	left: .05rem;
	top: .2rem;
}

#education-section .timeline-header {
	float: left;
	width: 35%;
	padding-right: 90px;
	text-align: right;
}
#education-section .timeline-header h3 {
	margin-bottom: 0;
}
#education-section .timeline-header p {
	font-family: "poppins-regular", sans-serif;
	font-size: 1.6rem;
	color: #888888;
}
#education-section .timeline-content {
	margin-left: 35%;
	padding-left: 60px;
}
#education-section .timeline-content h4 {
	position: relative;
	padding-bottom: 1.8rem;
}
#education-section .timeline-content h4::after {
	content: "";
	display: block;
	height: 3px;
	width: 50px;
	background: rgba(0, 0, 0, 0.2);
	position: absolute;
	left: 0;
	bottom: 0;
}

/**
 * responsive:
 * resume
 * ------------------------------------------------------------------- 
 */
@media only screen and (max-width:1024px) {
	#education-section .timeline-header {
		padding-right: 50px;
	}
	#education-section .timeline-header h3 {
		font-size: 1.8rem;
	}
	#education-section .timeline-header p {
		font-size: 1.4rem;
	}
	#education-section .timeline-content {
		padding-left: 50px;
	}
}
@media only screen and (max-width:768px) {
	#education-section .timeline-wrap::before {
		left: 2.4rem;
	}
	#education-section .timeline-ico {
		left: 2.4rem;
	}
	#education-section .timeline-header {
		float: none;
		width: auto;
		padding-right: 15px;
		text-align: left;
	}
	#education-section .timeline-header h3 {
		font-size: 2rem;
	}
	#education-section .timeline-header p {
		font-size: 1.5rem;
		margin-bottom: 1.5rem;
	}
	#education-section .timeline-content {
		margin: 0;
	}
	#education-section .timeline-content h4 {
		padding-bottom: 0;
		padding-top: 2.1rem;
		margin-bottom: .6rem;
		font-size: 1.7rem;
	}
	#education-section .timeline-content h4::after {
		bottom: auto;
		top: 0;
	}
	#education-section .timeline-header, #education-section .timeline-content {
		padding-left: 7rem;
	}
}
@media only screen and (max-width:480px) {
	#education-section .resume-header h2 {
		font-size: 2.2rem;
	}
	#education-section .timeline-wrap::before {
		left: 1.8rem;
	}
	#education-section .timeline-ico {
		height: 3.6rem;
		width: 3.6rem;
		line-height: 3.6rem;
		left: 1.8rem;
		margin-left: -1.8rem;
		font-size: 1.5rem;
	}
	#education-section .timeline-header, #education-section .timeline-content {
		padding-left: 5.5rem;
	}
}

